import { render, staticRenderFns } from "./AddSuperActivities.vue?vue&type=template&id=197c4b48"
import script from "./AddSuperActivities.vue?vue&type=script&lang=js"
export * from "./AddSuperActivities.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('197c4b48')) {
      api.createRecord('197c4b48', component.options)
    } else {
      api.reload('197c4b48', component.options)
    }
    module.hot.accept("./AddSuperActivities.vue?vue&type=template&id=197c4b48", function () {
      api.rerender('197c4b48', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/superActivities/AddSuperActivities.vue"
export default component.exports